<template>
    <div>
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"/>
        </keep-alive>
        <router-view  v-if="!$route.meta.keepAlive"/>
    </div>
</template>

<script>
    import wxValidate from '@/config/wx.validate'
    import {mapMutations,mapGetters} from 'vuex'
    export default {
        computed: {...mapGetters(['wxInfo'])},
        mixins: [wxValidate],
        name: "layout",
        created(){


        },


        mounted(){
            //this.wxSignature();
            /*let user = {
                id: "1301174891015221249",
                nickname: "Toby",
                phone: "13800138000",
                sex: 1,
                headImage: "http://thirdwx.qlogo.cn/mmopen/pfh9gxSGcRiaKDzUOfLBmUwSgBKUAko9XnabvqmeKiczLE3XGy8mib0JNQMawGBoIr8mBnqRoxS2wjAosFk1Gt2zhg7KQqzVTxy/132",
                province: "四川",
                city: "南充",
                status: 1,
                wxOpenId: "o8MiRwbxHYbjWiuUURCqIP0YBmog"
            };
            localStorage.setItem('wxInfo',JSON.stringify(user));
            // console.log('设置微信信息');
            this.SET_WX_INFO(user);*/
            /*if(!Object.keys(this.wxInfo).length){
                return this.$router.replace('/wx');
            }*/
        },
        methods: {
            ...mapMutations(['SET_WX_INFO','SET_USERINFO']),
            async getUserInfo(){
                const res = await this.$http.get('/mp/system/info');
                if(res.errorCode != '00000') return ;
                this.SET_USERINFO(res.data);
                localStorage.setItem('userinfo',JSON.stringify(res.data));
            }
        }

    }
</script>
